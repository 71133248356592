<template>
  <div>
    <vx-card>
      <div class="vx-row" v-if="!selectable">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Proyek</label>
          <v-select :options="proyeks" @input="onChangeProyek" label="nama" :reduce="item => item.id" :clearable="true" v-model="filter.id_proyek"/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Kategori</label>
          <v-select :options="propertiKategoris" @input="onChangeKategori" label="nama" :reduce="item => item.id" :clearable="true" v-model="filter.id_properti_kategori"/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Tipe</label>
          <v-select :options="propertiTipes" @input="onChangeTipe" label="nama" :reduce="item => item.id" :clearable="true" v-model="filter.id_properti_tipe"/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Cluster</label>
          <v-select :options="clusters" @input="onChangeCluster" label="nama" :reduce="item => item.id" :clearable="true" v-model="filter.id_cluster"/>
        </div>
      </div>

      <vs-divider v-if="!selectable"></vs-divider>

      <div class="flex flex-wrap justify-between items-center mb-1">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex">
            <vs-button v-if="!selectable" color="primary" type="border" icon-pack="feather" icon="icon-plus" class="px-3 mr-3" @click="modalAddActive = true">Tambah</vs-button>
          </div>
        </div>

        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>

            <!--actions-->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button class="mb-4 md:mb-0 px-3 py-3 flex items-center justify-center" type="filled" icon-pack="feather" icon="icon-more-vertical"/>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <ExportCsv v-if="$refs.mainTable" :data="$refs.mainTable._props.data" />
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        ref="mainTable"
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap" v-if="!selectable">Aksi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_proyek">Proyek</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="no_unit">No.Unit</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_properti_kategori">Properti Kategori</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_properti_tipe">Properti Tipe</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_cluster">Cluster</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="status_unit">Status Unit</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="view">View</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="luas_bangunan">Luas Bangunan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="luas_tanah">Luas Tanah</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="luas_efektif">Luas Efektif</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="spesifikasi">Spesifikasi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="lokasi">Lokasi</vs-th>

          <vs-th class="whitespace-no-wrap" sort-key="hpp_tanah">HPP Tanah</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="hpp_bangunan">HPP Bangunan</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="total_hpp">Total HPP</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="harga_standar">Harga Standar</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="harga_luas">Harga Luas</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="harga_tinggi">Harga Tinggi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="harga_view">Harga View</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="harga_mutu">Harga Mutu</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="biaya_adm">Biaya Adm</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="biaya_adm">Biaya Notaris</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="total_harga_jual">Total Harga Jual</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="harga_min">Harga Min</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="harga_max">Harga Max</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">Created At</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td class="whitespace-no-wrap" v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)">Pilih</vs-button>
            </vs-td>
            <vs-td class="whitespace-no-wrap" v-if="!selectable">
              <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                <vs-button class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                <vs-dropdown-menu>
                  <vs-dropdown-item class="p-1" @click="showModalEdit(item)"><span class="whitespace-no-wrap">Edit</span></vs-dropdown-item>
                  <vs-dropdown-item class="p-1" @click="showModalDetail(item)"><span class="whitespace-no-wrap">Detail</span></vs-dropdown-item>
                  <vs-dropdown-item class="p-1" @click="confirmDelete(item.id)"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.kode_proyek }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_unit }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.nama_properti_kategori }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.nama_properti_tipe }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.nama_cluster }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.status_unit }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.view }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.luas_bangunan }} M<sup>2</sup></vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.luas_tanah }} M<sup>2</sup></vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.luas_efektif }} M<sup>2</sup></vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.spesifikasi }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.lokasi }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.hpp_tanah | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.hpp_bangunan | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.hpp_tanah + item.hpp_bangunan | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.harga_standar_tanah + item.harga_standar_bangunan | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ (item.harga_luas || 0) | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ (item.harga_tinggi || 0) | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ (item.harga_view || 0) | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ (item.harga_mutu || 0) | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ (item.biaya_adm || 0) | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ (item.biaya_notaris || 0) | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.total_harga_jual | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ (item.harga_min || 0) | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ (item.harga_max || 0) | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.created_at }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>

    </vx-card>

    <!--modals-->
    <PropertiUnitAdd v-if="!selectable" :isActive.sync="modalAddActive" @success="getData"/>
    <PropertiUnitEdit v-if="!selectable" :isActive.sync="modalEditActive" @success="getData" :item="modalEditItem"/>
    <PropertiUnitDetail v-if="!selectable" :isActive.sync="modalDetailActive" @success="getData" :item="modalDetailItem"/>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import PropertiKategoriRepository from '@/repositories/master/properti-kategori-repository'
import PropertiTipeRepository from '@/repositories/master/properti-tipe-repository'
import PropertiClusterRepository from '@/repositories/master/cluster-repository'
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import _ from 'lodash'
import vSelect from 'vue-select'

export default {
  name: 'PropertiUnit',
  components: {
    PropertiUnitAdd: () => import('@/views/pages/master/properti-unit/PropertiUnitAdd'),
    PropertiUnitEdit: () => import('@/views/pages/master/properti-unit/PropertiUnitEdit'),
    PropertiUnitDetail: () => import('@/views/pages/master/properti-unit/PropertiUnitDetail'),
    ExportCsv: () => import('@/views/components/export-csv/ExportCsv'),
    'v-select': vSelect,
    PagingLimit
  },
  props: {
    selectable: { default: false, type: Boolean },
    externalFilter: { default: null, type: Object }
  },
  mounted () {
    this.getProyek()
    this.getCluster()
  },
  watch: {
    externalFilter (newVal, oldVal) {
      if (newVal && !_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    }
  },
  data () {
    return {
      filter: {},
      modalAddActive: false,
      modalEditActive: false,
      modalDetailActive: false,
      modalEditItem: {},
      modalDetailItem: {},
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      },
      proyeks: [],
      propertiKategoris: [],
      propertiTipes: [],
      clusters: []
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    },
    isEmptyFilter () {
      return _.isEmpty(this.filter)
    }
  },
  methods: {
    onChangeProyek () {
      this.propertiKategoris = []
      this.propertiTipes = []
      this.filter.id_properti_kategori = null
      this.filter.id_properti_tipe = null
      this.getData(true)
      this.getPropertiKategori()
    },

    onChangeKategori () {
      this.propertiTipes = []
      this.filter.id_properti_tipe = null
      this.getData(true)
      this.getPropertiTipe()
    },

    onChangeTipe () {
      this.getData(true)
    },

    onChangeCluster () {
      this.getData(true)
    },

    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      if (this.externalFilter) {
        params.externalFilter = JSON.stringify(this.externalFilter)
      }

      // filter
      if (!this.isEmptyFilter) {
        params.filter = JSON.stringify(this.filter)
      }

      PropertiUnitRepository.get(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    getProyek () {
      ProyekRepository.getAll()
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getPropertiKategori () {
      const idProyek = this.filter.id_proyek
      PropertiKategoriRepository.getPropertiKategoriByProyek(idProyek)
        .then(response => {
          this.propertiKategoris = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getPropertiTipe () {
      const idKategori = this.filter.id_properti_kategori
      PropertiTipeRepository.getAllPropertiTipeByKategori(idKategori)
        .then(response => {
          this.propertiTipes = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getCluster () {
      PropertiClusterRepository.getAllCluster()
        .then(response => {
          this.clusters = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    delete (id) {
      PropertiUnitRepository.delete(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    showModalEdit (item) {
      this.modalEditItem = item
      this.modalEditActive = true
    },
    showModalDetail (item) {
      this.modalDetailItem = item
      this.modalDetailActive = true
    },

    onPageChange () {
      this.getData()
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
